<template>
  <div class="k-9sptci" v-loading="loading">
    <el-form
      :inline="true"
      class="k-9sptci-header"
      :model="searchForm"
      ref="searchForm"
    >
      <el-form-item label="场次" prop="uid">
        <div v-if="session_name != ''">{{ session_name }}</div>
        <div v-else>
          <span v-if="matchType != 'league'">{{ `${group}` }}组</span
          >{{ `第${index}场` }}
        </div>
      </el-form-item>
      <div class="add-sect">
        <el-button
          class="add-btn"
          size="small"
          v-if="type_id === 's2' || type_id === 's3'"
          type="primary"
          @click="saveSect()"
          :disabled="isAddBtn"
          >新增</el-button
        >
        <!-- <el-button @click="submitSect">提交</el-button> -->
        <popconfirmButton
          :perm="`match:gruop_save`"
          content="是否确认提交？"
          text="提交"
          @onConfirm="submitSect"
        ></popconfirmButton>
      </div>
    </el-form>

    <el-table :data="dataTable" style="margin-top:20px" border>
      <template v-for="item in dataList">
        <el-table-column
          :key="item._id"
          :prop="item.prop"
          :type="item.type"
          :label="item.label"
          :width="item.width"
          :formatter="item.formatter"
        >
        </el-table-column>
      </template>

      <el-table-column label="操作" fixed="right">
        <template slot-scope="scope">
          <perButton
            :perm="`match:stage_group`"
            @click="changeGroup(scope.row)"
            style="margin-left: 10px"
            >替换门派</perButton
          >
          <el-select
            class="resultLeft"
            v-if="type_id === 's2' || type_id === 's3'"
            v-model="scope.row.sect_status"
            placeholder="请选择"
            @change="selectSect(scope.row._id, scope.row.sect_status)"
          >
            <el-option
              v-for="item in resultSects"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
    </el-table>

    <change_group
      page_name="group_session"
      :match_id="this.match_id"
      :stage="stage"
      :from_data="groupData"
      :index="index"
      v-if="groupVisible"
      @changed="onGroupChanged"
      @closed="groupVisible = false"
    ></change_group>
    <!-- 设置添加门派id -->
    <el-dialog
      v-loading="loading"
      :visible.sync="patternVisible"
      v-if="patternVisible"
      :close-on-click-modal="false"
      width="500px"
    >
      <div>
        <div>
          <el-form ref="patternForm" :model="patternData" label-width="100px">
            <el-form-item
              label="门派id"
              prop="sect_id"
              :rules="[
                {
                  required: true,
                  message: '请输入门派id',
                  trigger: ['blur'],
                },
              ]"
            >
              <el-input
                v-model="patternData.sect_id"
                type="text"
                placeholder="输入门派id"
              >
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="savePattern">确定</el-button>
              <el-button @click="patternVisible = false">取消</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import perButton from "@/components/perm/perButton";
import { randomStr } from "@/utils/randomStr";
import change_group from "./change_group";
export default {
  name: "s1_session_group",
  components: {
    perButton,
    change_group,
  },
  computed: {
    ...mapState("match", ["match_types"]),
    //获取结果为暂无的个数
    countSectStatus() {
      return this.dataTable.filter((item) => item.sect_status == 0).length;
    },
  },
  data() {
    return {
      itemsTable: [],
      isAddBtn: false,
      matchType: "",
      patternData: {
        sect_id: "",
      },
      ids: [],
      type_id: "s1",
      match_id: "",
      loading: false,
      stage: "",
      index: "",
      group: "",
      session_name: "",
      session_id: "",
      stage_status: "",
      searchForm: {
        sect_id: "",
      },
      resultSects: [
        {
          id: 0,
          value: "暂无",
        },
        {
          id: 1,
          value: "无效：门派弃赛",
        },
        {
          id: 2,
          value: "败：门派被取消资格",
        },
        {
          id: 3,
          value: "败：门派降为替补",
        },
      ],
      dataTable: [],
      dataList: [
        {
          prop: "group",
          label: "分组",
          width: 300,
        },
        {
          prop: "sect_id",
          label: "门派ID",
          width: 300,
        },
        {
          prop: "sect_name",
          label: "门派名称",
          width: 200,
        },
      ],
      groupVisible: false,
      groupData: {
        from_id: "",
        from_group: "",
        from_sect_id: "",
        from_sect_name: "",
        to_id: "",
      },
      match: false,
      patternVisible: false,
    };
  },
  watch: {
    $route: {
      handler: function(val) {
        this.type_id = val.query.type_id;
        this.match_id = this.$route.query.match_id;
        this.stage = this.$route.query.stage;
        this.index = this.$route.query.index;
        this.group = this.$route.query.group;
        this.session_name = this.$route.query.session_name;
        this.session_id = this.$route.query.session_id;
        this.matchType = localStorage.getItem("matchType");
      },
      immediate: true,
    },
  },
  mounted() {
    this.$store.commit("tabs/setVNode", this.$vnode);
    this.handleQuery();
  },
  methods: {
    //新增
    saveSect() {
      console.log(this.countSectStatus); // 输出：2
      if (this.countSectStatus >= 12) {
        this.$message({
          type: "error",
          message: "本场次门派已经加满，不能在新增",
        });
        return;
      } else {
        this.patternVisible = true;
        this.patternData.sect_id = "";
      }
    },
    // 提交
    async submitSect() {
      let saveData = {
        match_id: this.match_id,
        session_id: this.session_id,
      };
      let res = await this.$http.match.submit_sect_update_s2(saveData);
      if (res.code == 0) {
        this.$message({
          type: "success",
          message: "提交成功",
        });
      }
    },
    changeGroup(row) {
      this.groupData = {
        _id: row._id,
        group: row.group,
        sect_id: row.sect_id,
        sect_name: row.sect_name,
      };

      this.groupVisible = true;
    },
    //新增确定按钮
    async savePattern() {
      this.$refs.patternForm.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          let saveData = {
            match_id: this.match_id,
            session_id: this.session_id,
            sect_id: this.patternData.sect_id,
            stage: this.stage,
            group: this.group,
            index: this.index,
          };

          let res = await this.$http.match.session_add_group_s2(saveData);
          this.loading = false;
          if (res.code == 0) {
            this.$message({
              type: "success",
              message: "新增成功",
            });
            this.patternVisible = false;
            this.handleQuery();
          }
        }
      });
    },
    onGroupChanged(data) {
      this.handleQuery();
    },
    searchClick() {
      this.$refs.patternForm.validate((v) => {});
      this.handleQuery();
    },
    async selectSect(_id, sect_status) {
      let saveData = {
        _id: _id,
        sect_status: sect_status,
        match_id: this.match_id,
      };
      let res = await this.$http.match.update_sect_status_s2(saveData);
      if (res.code != 0) {
        this.handleQuery();
      }
    },
    async handleQuery() {
      const { data, errorCode } = await this.$http.match.stage_group_list({
        ...this.searchForm,
        match_id: this.match_id,
        stage: this.stage,
        group: this.group,
        index: this.index,
      });
      if (errorCode != "0000") return;
      this.dataTable = data.data;
      this.stage_status = data.stage_status || "";
    },

    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ]),
  },
  destroyed() {},
};
</script>
<style lang="less" scoped>
.k-9sptci-header {
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
  .add-sect {
    padding-right: 20px;
  }
  .el-form-item {
    margin-bottom: 0;
  }
}
.k-9sptci-footer {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
}
.resultLeft {
  margin-left: 20px;
}
.add-btn {
  margin-right: 20px;
}
</style>
